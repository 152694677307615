import { FC } from 'react';
import IconProps from './IconProps';

const MailIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5783 7.59062C5.12773 7.27123 4.50356 7.37757 4.18418 7.82814C3.86479 8.27871 3.97113 8.90288 4.4217 9.22227L5.5783 7.59062ZM10.9527 12.626L11.531 11.8102L11.531 11.8102L10.9527 12.626ZM12.6266 12.626L12.0483 11.8102L12.0482 11.8102L12.6266 12.626ZM19.1576 9.22227C19.6081 8.90288 19.7145 8.27871 19.3951 7.82814C19.0757 7.37757 18.4516 7.27123 18.001 7.59062L19.1576 9.22227ZM4.4217 9.22227L10.3744 13.4419L11.531 11.8102L5.5783 7.59062L4.4217 9.22227ZM10.3744 13.4418C11.2276 14.0467 12.3517 14.0467 13.2049 13.4418L12.0482 11.8102C11.8879 11.9239 11.6914 11.9239 11.531 11.8102L10.3744 13.4418ZM13.2049 13.4419L19.1576 9.22227L18.001 7.59062L12.0483 11.8102L13.2049 13.4419ZM6.50881 18.23H17.0705V16.23H6.50881V18.23ZM17.0705 18.23C18.513 18.23 19.5793 17.0054 19.5793 15.6257H17.5793C17.5793 16.0182 17.2946 16.23 17.0705 16.23V18.23ZM19.5793 15.6257V7.60429H17.5793V15.6257H19.5793ZM19.5793 7.60429C19.5793 6.22468 18.513 5 17.0705 5V7C17.2946 7 17.5793 7.21185 17.5793 7.60429H19.5793ZM17.0705 5H6.50881V7H17.0705V5ZM6.50881 5C5.06631 5 4 6.22467 4 7.60429H6C6 7.21186 6.28472 7 6.50881 7V5ZM4 7.60429V15.6257H6V7.60429H4ZM4 15.6257C4 17.0054 5.06632 18.23 6.50881 18.23V16.23C6.28471 16.23 6 16.0182 6 15.6257H4Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MailIcon;
